import React from "react";
import pic1 from "../assets/images/APE (1).png";
import pic2 from "../assets/images/IMG_2558.PNG";
import pic3 from "../assets/images/snoop (2).png";
import pica from "../assets/images/Untitled design (21).png";
import picb from "../assets/images/2.png";
import picc from "../assets/images/11.png";
import pic4 from "../assets/images/4.png";
import pic5 from "../assets/images/5.png";
import pic6 from "../assets/images/6.png";
import pic7 from "../assets/images/7.png";
import pic8 from "../assets/images/8.png";
import pic9 from "../assets/images/9.png";
import pic10 from "../assets/images/10.png";
import pic11 from "../assets/images/11.png";
import pic12 from "../assets/images/bitcoin dude.png";
import pic13 from "../assets/images/13.png";
import pic14 from "../assets/images/6.png";
import pic15 from "../assets/images/7.png";
import {Fade} from "react-reveal";

import wife3 from "../assets/images/wife3.png";
import wife4 from "../assets/images/wife4.png";
import wife5 from "../assets/images/wife5.png";

const Roadmap = ({isFemale}) => {
  return (
    <div className="w-full">
      <div className="max-w-6xl mx-auto px-6 py-20">
        <Fade bottom>
          <h1 className="text-center text-primary font-extrabold italic text-4xl md:text-6xl uppercase mb-4">
            Roadmap
          </h1>
        </Fade>
        <Fade bottom>
          <p className="text-center text-white text-xl md:text-2xl font-medium uppercase mb-16">
            COmmunity X utility x Brand Tm
          </p>
        </Fade>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-20">
          <Fade bottom>
            <div>
              <img src={isFemale?wife3:pic1} alt="" />
            </div>
          </Fade>
          <Fade bottom>
            <div>
              <img src={isFemale?wife4:pic2} alt="" />
            </div>
          </Fade>
          <Fade bottom>
            <div>
              <img src={isFemale?wife5:pic3} alt="" />
            </div>
          </Fade>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-10">
          <div>
            <Fade bottom>
              <h1 className=" text-xl md:text-2xl text-primary font-bold capitalize mb-4 md:h-10 text-center">
                VIP Pre Sale
              </h1>
            </Fade>
            <Fade bottom>
              <div className="flex justify-center">
                <img src={pica} alt="" className="mb-6 w-28" />
              </div>
            </Fade>
            <Fade bottom>
              <p className="text-white font-semibold text-base text-center">
                We have 1000 VIP whitelist spots available in total.VIP
                whitelist sale pricing is 0.08 ETH. Will be open for 24 hours to
                avoid gas war.1,000,000 $AList Token distribution to VIP Minters
                24 hours after mint.
              </p>
            </Fade>
          </div>
          <div>
            <Fade bottom>
              <h1 className=" text-xl md:text-2xl text-primary font-bold capitalize mb-4 md:h-10 text-center">
                Public sale LAUNCH
              </h1>
            </Fade>
            <Fade bottom>
              <div className="flex justify-center">
                <img src={picb} alt="" className="mb-6 w-28" />
              </div>
            </Fade>
            <Fade bottom>
              <p className="text-white font-semibold text-base text-center">
                Public sale pricing is 0.16 ETH.48 hours after whitelist launch.{" "}
              </p>
            </Fade>
          </div>
          <div>
            <Fade bottom>
              <h1 className=" text-xl md:text-2xl text-primary font-bold capitalize mb-4 md:h-10 text-center">
                75% SOLD GIVEAWAY
              </h1>
            </Fade>
            <Fade bottom>
              <div className="flex justify-center">
                <img src={picc} alt="" className="mb-6 w-28" />
              </div>
            </Fade>
            <Fade bottom>
              <p className="text-white font-semibold text-base text-center">
                Massive Giveaway of $356,000 Crypto Prizes + NFT's + Custom
                Wrapped Lambo!!! (which we will make an NFT!) At 75% sold
                giveaways will start and winners will be announced.{" "}
              </p>
            </Fade>
          </div>
          <div>
            <Fade bottom>
              <h1 className=" text-xl md:text-2xl text-primary font-bold capitalize mb-4 md:h-10 text-center">
                $AList Token Creation
              </h1>
            </Fade>
            <Fade bottom>
              <div className="flex justify-center">
                <img src={pic4} alt="" className="mb-6 w-28" />
              </div>
            </Fade>
            <Fade bottom>
              <p className="text-white font-semibold text-base text-center">
                1,000,000 A List Token distribution to VIP Minters 24 hours
                after mint.Only 21 Million A List token creation.* 20% of VIP
                funds will go to community wallet for liquidity.Tokenized
                Economy
              </p>
            </Fade>
          </div>
          <div>
            <Fade bottom>
              <h1 className=" text-xl md:text-2xl text-primary font-bold capitalize mb-4 md:h-10 text-center">
                Airdrops
              </h1>
            </Fade>
            <Fade bottom>
              <div className="flex justify-center">
                <img src={pic5} alt="" className="mb-6 w-28" />
              </div>
            </Fade>
            <Fade bottom>
              <p className="text-white font-semibold text-base text-center">
                We love airdrops! We will be doing many A List airdrops with
                cool free shit.
              </p>
            </Fade>
          </div>
          <div>
            <Fade bottom>
              <h1 className=" text-xl md:text-2xl text-primary font-bold capitalize mb-4 md:h-10 text-center">
                Creation of Wife Apes & Trust Fund Apes
              </h1>
            </Fade>
            <Fade bottom>
              <div className="flex justify-center">
                <img src={pic6} alt="" className="mb-6 w-28" />
              </div>
            </Fade>
            <Fade bottom>
              <p className="text-white font-semibold text-base text-center">
                Must hold an A List Ape to mint Wives and Babies.Reality sets in
                and the Metaverse will never be the same.
              </p>
            </Fade>
          </div>
          <div>
            <Fade bottom>
              <h1 className=" text-xl md:text-2xl text-primary font-bold capitalize mb-4 md:h-10 text-center">
                Stacking NFT Platform
              </h1>
            </Fade>
            <Fade bottom>
              <div className="flex justify-center">
                <img src={pic7} alt="" className="mb-6 w-28" />
              </div>
            </Fade>
            <Fade bottom>
              <p className="text-white font-semibold text-base text-center">
                We sell out and NFT launchpad gets released other new projects
                can feature. Their launches on ALIST.io - ALL OUR NEXT PROJECTS
                WILL BE FEATURED THERE AS WELL.Staking details will be released
                prior to pre-sale.
              </p>
            </Fade>
          </div>
          <div>
            <Fade bottom>
              <h1 className=" text-xl md:text-2xl text-primary font-bold capitalize mb-4 md:h-10 text-center">
                NFT Launchpad
              </h1>
            </Fade>
            <Fade bottom>
              <div className="flex justify-center">
                <img src={pic8} alt="" className="mb-6 w-28" />
              </div>
            </Fade>
            <Fade bottom>
              <p className="text-white font-semibold text-base text-center">
                Alist.io create a tokenized economy for creators and
                investors.(In development) The plan with Alist.io is to create
                new NFT projects once per month.Also to partner with other NFT
                platforms to grow brand and other blockchains.
              </p>
            </Fade>
          </div>
          <div>
            <Fade bottom>
              <h1 className=" text-xl md:text-2xl text-primary font-bold capitalize mb-4 md:h-10 text-center">
                A LIST DAO
              </h1>
            </Fade>
            <Fade bottom>
              <div className="flex justify-center">
                <img src={pic9} alt="" className="mb-6 w-28" />
              </div>
            </Fade>
            <Fade bottom>
              <p className="text-white font-semibold text-base text-center">
                Fund invests in other big projects and create a growing - NFT
                Fund Community Voting - Community Wallet
              </p>
            </Fade>
          </div>
          <div>
            <Fade bottom>
              <h1 className=" text-xl md:text-2xl text-primary font-bold capitalize  text-center">
                Digital Real Estate Acquisition & Gaming Partnerships
              </h1>
            </Fade>
            <Fade bottom>
              <div className="flex justify-center">
                <img src={pic10} alt="" className="mb-6 w-28" />
              </div>
            </Fade>
            <Fade bottom>
              <p className="text-white font-semibold text-base text-center">
                Land will be acquired on either Sandbox or Axie.Gaming
                partnerships are in the works. Beta Video game launch March
                2022.
              </p>
            </Fade>
          </div>
          <div>
            <Fade bottom>
              <h1 className=" text-xl md:text-2xl text-primary font-bold capitalize mb-4 md:h-10 text-center">
                Celebrit & A List Marketing
              </h1>
            </Fade>
            <Fade bottom>
              <div className="flex justify-center">
                <img src={pic11} alt="" className="mb-6 w-28" />
              </div>
            </Fade>
            <Fade bottom>
              <p className="text-white font-semibold text-base text-center">
                Celebrities partnerships - create 1 of 1 celebrities and
                continue outreach until we cultivate a strong influence in
                connecting with celebrities, athletes, influencers, and
                musicians.
              </p>
            </Fade>
          </div>
          <div>
            <Fade bottom>
              <h1 className=" text-xl md:text-2xl text-primary font-bold capitalize mb-4 md:h-10 text-center">
                Merch Store
              </h1>
            </Fade>
            <Fade bottom>
              <div className="flex justify-center">
                <img src={pic12} alt="" className="mb-6 w-28" />
              </div>
            </Fade>
            <Fade bottom>
              <p className="text-white font-semibold text-base text-center">
                Merch only for holders.(scarcity)
              </p>
            </Fade>
          </div>
          <div>
            <Fade bottom>
              <h1 className=" text-xl md:text-2xl text-primary font-bold capitalize mb-2  text-center">
                Comic Book Series & Cartoon Animation
              </h1>
            </Fade>
            <Fade bottom>
              <div className="flex justify-center">
                <img src={pic13} alt="" className="mb-6 w-28" />
              </div>
            </Fade>
            <Fade bottom>
              <p className="text-white font-semibold text-base text-center">
                Create a physical comic book series to develop storylines. Then
                develop into cartoons.
              </p>
            </Fade>
          </div>
          <div>
            <Fade bottom>
              <h1 className=" text-xl md:text-2xl text-primary font-bold capitalize mb-4 md:h-10 text-center">
                Podcast Launch
              </h1>
            </Fade>
            <Fade bottom>
              <div className="flex justify-center">
                <img src={pic14} alt="" className="mb-6 w-28" />
              </div>
            </Fade>
            <Fade bottom>
              <p className="text-white font-semibold text-base text-center">
                Interview main OG characters trying to draw the biggest
                interviews.
              </p>
            </Fade>
          </div>
          <div>
            <Fade bottom>
              <h1 className=" text-xl md:text-2xl text-primary font-bold capitalize mb-4 md:h-10 text-center">
                TBC
              </h1>
            </Fade>
            <Fade bottom>
              <div className="flex justify-center">
                <img src={pic15} alt="" className="mb-6 w-28" />
              </div>
            </Fade>
            <Fade bottom>
              <p className="text-white font-semibold text-base text-center">
                To be continued... A List Ape Style!
              </p>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
