import React, { useEffect } from "react";
import { FaDiscord, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { Fade } from "react-reveal";
import { useWallet } from "use-wallet";

const Header = ({ BottomPanel,isFemale }) => {
  const { account, connect } = useWallet();

  useEffect(() => {
    connect();
  }, []);



  return (
    <div className="w-full header-bg overflow-hidden" style={{
      backgroundImage:!isFemale?"url('https://sudipto-data.s3.amazonaws.com/website+background+video.dfc85806-min.gif')":"url('/femaleBanner.gif')"
    }}>
      <div className="flex justify-end py-6 px-6">
        <div className="flex">
          <a
            href="https://discord.com/invite/h2xhY2Qnsb"
            target="_blank"
            className="mx-2"
          >
            <FaDiscord className="w-8 h-8 text-white" />
          </a>
          <a
            href="https://www.instagram.com/alistapesnft"
            target="_blank"
            className="mx-2"
          >
            <FaInstagram className="w-8 h-8 text-white" />
          </a>
          <a
            href="https://twitter.com/AListApesNFT"
            target="_blank"
            className="mx-2"
          >
            <FaTwitter className="w-8 h-8 text-white" />
          </a>
          <a
            href="https://www.youtube.com/channel/UC7fbAvbTw6DsWL60vJN7qEQ"
            target="_blank"
            className="mx-2"
          >
            <FaYoutube className="w-8 h-8 text-white" />
          </a>

          <a
            href="https://opensea.io/assets/0xdc299dc0bfee847d045f8a24184884a28c049d32/0"
            target="_blank"
            className="mx-2"
          >
            <img
              src="https://opensea.io/static/images/logos/opensea.svg"
              className="w-8 h-8 text-white"
            />
          </a>


          <a
            href="https://opensea.io/assets/0xfcae16a3247281a7b1dbabb8cb788810fb525031/0"
            target="_blank"
            className="mx-2"
          >
            <img
              src="https://opensea.io/static/images/logos/opensea.svg"
              className="w-8 h-8 text-white"
            />
          </a>
          <a
            href="https://rarible.com/alistapes"
            target="_blank"
            className="mx-2"
          >
            <img
              src="https://rarible.com/favicon.png"
              className="w-8 h-8 text-white"
            />
          </a>
        </div>
      </div>
      <div className="flex justify-center items-end  h-full pb-28 ">
        <div>{BottomPanel ? <BottomPanel /> : null}</div>
      </div>
    </div>
  );
};

export default Header;
