import React from "react";
import Community from "../components/Community";
import Header from "../components/Header";
import Join from "../components/Join";
import ListApes from "../components/ListApes";
import Roadmap from "../components/Roadmap";
import SubmitEmail from "../components/SubmitEmail";
import Footer from "../components/Footer";
import FAQ from "../components/FAQ";
import HowToMint from "../components/HowToMint";
import FemaleMint from "../components/FemaleMint";

const FemaleApes = () => {
  return (
    <div>
      <Header BottomPanel={FemaleMint} isFemale/>
      <HowToMint />

      <ListApes  isFemale/>

      {/* <SubmitEmail /> */}
      <Roadmap  isFemale/>
      <Community />
      <FAQ />
      <Join isFemale/>
      <Footer />
    </div>
  );
};

export default FemaleApes;
