import React from "react";
import {Fade} from "react-reveal";
import {Collapse} from "antd";
import {PlusOutlined, MinusOutlined} from "@ant-design/icons";

const {Panel} = Collapse;

const FAQ = () => {
  return (
    <div>
      <div className="w-full">
        <div className="max-w-6xl mx-auto px-4 py-10">
          <Fade bottom>
            <h1 className="text-center text-primary font-extrabold italic text-4xl md:text-6xl uppercase mb-16">
              CRYPTO FAQ
            </h1>
            <div className="mb-14">
              <Collapse
                bordered={false}
                expandIcon={({isActive}) =>
                  isActive
                    ? <MinusOutlined
                        className={isActive ? "text-primary" : " text-primary"}
                        style={{fontSize: "150%"}}
                      />
                    : <PlusOutlined
                        className={isActive ? "text-primary" : " text-primary"}
                        style={{fontSize: "150%"}}
                      />}
                expandIconPosition="right"
                className="site-collapse-custom-collapse bg-secondary"
              >
                {generalQuestion.map(item => {
                  return (
                    <Panel
                      header={item.title}
                      key={item.id}
                      className="text-offWhite  mb-4"
                    >
                      <Fade>
                        <p className="pr-10 text-base font-medium text-white py-7">
                          {item.description}
                        </p>
                      </Fade>
                    </Panel>
                  );
                })}
              </Collapse>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default FAQ;

const generalQuestion = [
  {
    id: 1,
    title: "WHAT’S AN NFT?",
    description: `NFT stands for “Non-fungible token”, which is a unique digital asset stored on the Ethereum Blockchain. NFTs can verify that a digital piece of art, an event ticket, or memorabilia is one-of-a-kind.`
  },
  {
    id: 2,
    title: "WHERE WILL I BE ABLE TO MINT?",
    description: `The minting event for the pre-sale and the public launch will take place on our one and only website, alistapes.com . You will be able to mint using ETH and a MetaMask wallet. Once minting is completed, you will be able to view your A List Apes in your MetaMask wallet and on OpenSea.`
  },
  {
    id: 3,
    title: "WHEN IS THE PRESALE?",
    description: ` The presale date will take place in December 19th, 2021. There will be 1,000 spots in the presale, and all the whitelisted members will be guaranteed of minting at least one A List Ape at a 50% discount.`
  },
  {
    id: 4,
    title: "WHEN IS THE PUBLIC SALE? ",
    description: `PThe public sale will take place on December 20th, 2021, at 9 PM EST, directly on our website, alistapes.com.`
  },
  {
    id: 5,
    title: "SO... WHAT’S THE PRICE?",
    description: `The presale price is 0.08 ETH and the public sale price is 0.16 ETH. `
  },
  {
    id: 5,
    title: "THIS SOUNDS COOL, HOW DO I GET INVOLVED?",
    description: `Head over to the Discord: https://discord.gg/h2xhY2Qnsb jump in on the conversation and let us know your ideas!
    `
  }
];
