import React from "react";
import Home from "./pages/Home";
import FemaleApes from "./pages/FemaleApes"

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";


function App() {
  return (
    <div>
      <Router>


      <Route path="/" exact component={Home} />
      <Route path="/wives"  component={FemaleApes} />
      </Router>

    </div>
  );
}

export default App;
