import React from "react";
import male from "../assets/images/join.png";
import female from "../assets/images/femaleApes.jpeg";

import {Fade} from "react-reveal";


const Join = ({isFemale}) => {
  return (
    <div className="w-full">
      <Fade bottom>
        <h1 className="text-center text-primary font-extrabold italic text-4xl md:text-6xl uppercase mb-8">
          JOIN THE A LIST APES{" "}
          <span className="text-primary font-extrabold text-base">TM</span>
        </h1>
      </Fade>
      <Fade bottom>
        <img src={isFemale?female:male} alt="" className="w-full" />
      </Fade>
    </div>
  );
};

export default Join;
