import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "swiper/swiper-bundle.min.css";
import { useWallet, UseWalletProvider } from "use-wallet";

ReactDOM.render(
  <React.StrictMode>
    <UseWalletProvider
      chainId={1}
      connectors={{
        walletconnect: {
          rpc: {
            1: "https://speedy-nodes-nyc.moralis.io/cf8b736636d60bdd2970f22a/eth/mainnet",
          },
        },
      }}
    >
      <App />
    </UseWalletProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
