import React from "react";
import pica from "../assets/images/IMG_2558.PNG";
import picb from "../assets/images/Untitled design (21).png";




import female1 from "../assets/images/wife1.png";
import female2 from "../assets/images/wife2.png";
import team1 from "../assets/images/11d82015da527691980163e607006a63.png";
import team2 from "../assets/images/653de415789a352663e8e044dbed3313.png";
import { FaDiscord } from "react-icons/fa";
import { Fade } from "react-reveal";
import YouTube from "react-youtube";

const opts = {
  // height: "120",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
  },
};

const ListApes = ({isFemale}) => {
  return (
    <div className="w-full overflow-hidden">
      <div className="max-w-6xl mx-auto px-4 py-20">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-14 mb-32">
          <div>
            <Fade bottom>
              <h1 className=" text-5xl md:text-6xl text-primary font-bold italic mb-10">
                A LIST {isFemale?"Wives":"Apes"}<span className="text-sm pl-2 text-white">TM</span>
              </h1>
            </Fade>
            <Fade bottom>
              <p className="text-white font-semibold text-lg md:text-xl pb-10 md:pb-32">
                Our mission is to build the strongest NFT community period made
                of A Lister's guys and gals who hold themselves to the highest
                standards.We do not settle, we push the envelope, we are here to
                learn and improve ourselves on the daily basis.The NFT space is
                in it's infancy, blockchain, crypto, nfts are changing the
                world.We are here to build a community of like minded people who
                will support and uplift each other and thru this bond create a
                global A LIST BRAND.We are going mainstream and nobody can stop
                us.Community x Utility x Brand TM
              </p>
            </Fade>
            <Fade bottom>
              <a
                href="https://discord.com/invite/h2xhY2Qnsb"
                target="_blank"
                className="flex"
              >
                <span className="text-2xl text-white underline">
                  Join
                  <br /> Discord
                </span>
                <div className="flex self-center">
                  <span className="pl-10">
                    <FaDiscord className="w-10 h-10 text-discordcl" />
                  </span>
                  <span className="text-3xl pl-1 font-bold text-discordcl">
                    Discord
                  </span>
                </div>
              </a>
            </Fade>
          </div>





          <div className=" relative">
            <Fade bottom>
              <img
                src={isFemale?female1: pica}
                alt=""
                className=" w-full md:w-80 md:h-96 md:object-cover mb-10 md:mb-0"
              />
            </Fade>
            <Fade bottom>
              <img
                src={isFemale?female2: picb}
                alt=""
                className=" w-full md:w-96 md:absolute md:right-0 md:top-60 z-50"
              />
            </Fade>
          </div>
        </div>




        <h1 className=" text-center text-primary font-extrabold italic text-4xl md:text-6xl uppercase mb-10">
              Vibe with  {isFemale?"A-LIST Apes":"A-LIST Wives"} 
              </h1>
        <div className="max-w-5xl mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-10 mb-10">
            <div>
              <Fade bottom>
                <h1 className="text-white text-center text-3xl md:text-4xl font-bold mb-5 md:mb-10">
                  "A List Ape Life"
                </h1>
              </Fade>
              <Fade bottom>
                {/* <img
                  src={team1}
                  alt=""
                  className="w-full md:h-60 md:object-cover"
                /> */}

                <div className="w-full md:h-60 md:object-cover">
                  <YouTube
                    videoId="yLgkgJdhvRI"
                    opts={opts}
                    className="w-full md:h-60 md:object-cover"
                  />
                </div>
              </Fade>
            </div>
            <div>
              <Fade bottom>
                <h1 className="text-white text-center text-3xl md:text-4xl font-bold mb-5 md:mb-10">
                  "Roadmap Vibe"
                </h1>
              </Fade>
              <Fade bottom>
              <div className="w-full md:h-60 md:object-cover">
                  <YouTube
                    videoId="bih-M38KZbM"
                    opts={opts}
                    className="w-full md:h-60 md:object-cover"
                  />
                </div>


              </Fade>
            </div>

            <div>
              <Fade bottom>
                <h1 className="text-white text-center text-3xl md:text-4xl font-bold mb-5 md:mb-10">
                  "Roadmap Vibe"
                </h1>
              </Fade>
              <Fade bottom>
              <div className="w-full md:h-60 md:object-cover">
                  <YouTube
                    videoId="3vmTSuzfoDU"
                    opts={opts}
                    className="w-full md:h-60 md:object-cover"
                  />
                </div>


              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListApes;
