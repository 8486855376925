import React from "react";
import Community from "../components/Community";
import Header from "../components/Header";
import Join from "../components/Join";
import ListApes from "../components/ListApes";
import Roadmap from "../components/Roadmap";
import SubmitEmail from "../components/SubmitEmail";
import Footer from "../components/Footer";
import FAQ from "../components/FAQ";
// import MintInfo from "../components/FemaleMint"
import MaleMint from "../components/MaleMint";


const Home = () => {
  return (
    <div>
      <Header BottomPanel={MaleMint}/>

      <ListApes />

      {/* <SubmitEmail /> */}
      <Roadmap />
      <Community />
      <FAQ />
      <Join />
      <Footer />
    </div>
  );
};

export default Home;
