import React from "react";
import team1 from "../assets/images/11.png";
import team2 from "../assets/images/7fa94155c38618993357fc46523c8c96.png";
import team3 from "../assets/images/vitalik shirt.png";
import teama from "../assets/images/11d82015da527691980163e607006a63.png";
import teamb from "../assets/images/653de415789a352663e8e044dbed3313.png";
import { Fade } from "react-reveal";

import YouTube from 'react-youtube';


const opts = {
  // height: "120",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
  },
};



const Community = () => {
  return (
    <div className="w-full">
      <div className="max-w-6xl mx-auto px-4 py-20">
        <Fade bottom>
          <h1 className="text-center text-primary font-extrabold italic text-4xl md:text-6xl uppercase mb-4">
            COmmunity X utility x Brand
          </h1>
        </Fade>
        <Fade bottom>
          <p className="text-center text-primary italic text-xl font-medium uppercase mb-16">
            TEAM
          </p>
        </Fade>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-32">
          <div>
            <Fade bottom>
              <img src={team1} alt="" className="w-full " />
            </Fade>
            <Fade bottom>
              <div className="bg-primary px-8 py-8">
                <p className="text-secondary text-lg pb-5 font-semibold">
                  Serial Entrepreneur, been in the space for a while.Building
                  businesses to scale for 20 years.Family man, 3 young kids,
                  they help design the apes. :)
                </p>
                <p className="text-secondary italic text-lg font-bold">
                  Crypto Nick - Co - Founder
                </p>
              </div>
            </Fade>
          </div>
          <div>
            <Fade bottom>
              <img src={team2} alt="" className="w-full " />
            </Fade>
            <Fade bottom>
              <div className="bg-primary px-8 py-8">
                <p className="text-secondary text-lg pb-5 font-semibold">
                  Tech & Marketing Wizard, Ecommerce, Paid Ads, Everything API
                  based, Auto-Notification is my nick name.
                </p>
                <p className="text-secondary italic text-lg font-bold">
                  Zach H. - Co - Founder
                </p>
              </div>
            </Fade>
          </div>
          <div>
            <Fade bottom>
              <img src={team3} alt="" className="w-full " />
            </Fade>
            <Fade bottom>
              <div className="bg-primary px-8 py-8">
                <p className="text-secondary text-lg pb-5 font-semibold">
                  Blockchain developer since 2015.Smart Contract, Tokenomics,
                  DAO, Anything you need tech wise.
                </p>
                <p className="text-secondary italic text-lg font-bold">
                  Shaz Ape - Developer
                </p>
              </div>
            </Fade>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-14">
          {/* <div className="mb-10">
            <Fade bottom>
              <h1 className=" text-primary text-center uppercase text-4xl font-bold mb-6">
                TEAM TALK # 1
              </h1>
            </Fade>
            <Fade bottom>
              <div className="w-full md:object-cover">
                <YouTube
                  videoId="Ds9wjVsL8Bk"
                  opts={opts}
                  className="w-full md:h-60 md:object-cover"
                />
              </div>{" "}
            </Fade>
          </div>
          <div className="mb-10">
            <Fade bottom>
              <h1 className=" text-primary text-center uppercase text-4xl font-bold mb-6">
                TEAM TALK # 2
              </h1>
            </Fade>
            <Fade bottom>
              <div className="w-full md:object-cover">
                <YouTube
                  videoId="zOl-LPmdQcA"
                  opts={opts}
                  className="w-full md:h-60 md:object-cover"
                />
              </div>{" "}
            </Fade>

          </div> */}
          <div className="mb-10">
            <Fade bottom>
              <h1 className=" text-primary text-center uppercase text-4xl font-bold mb-6">
                TEAM TALK # 3
              </h1>
            </Fade>
            <Fade bottom>
              <div className="w-full md:object-cover">
                <YouTube
                  videoId="YtWu3Ebtvi4"
                  opts={opts}
                  className="w-full md:h-60 md:object-cover"
                />
              </div>{" "}
            </Fade>
          </div>

          
          <div className="mb-10">
            <Fade bottom>
              <h1 className=" text-primary text-center uppercase text-4xl font-bold mb-6">
                TEAM TALK # 4
              </h1>
            </Fade>
            <Fade bottom>
              <img src={teamb} alt="" className="w-full " />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Community;
