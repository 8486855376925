import React from "react";
import {Fade} from "react-reveal";
import config from "../BlockchainProvider/config";
const Footer = () => {
  return (
    <div className="w-full bg-primary">
      <div className="max-w-6xl mx-auto px-4 pt-10">
        <div className="flex flex-wrap justify-center md:justify-between">
          <Fade bottom>
            <div className="mb-10">
              <h1 className="text-2xl font-extrabold italic pb-1 text-secondary">
                HEADQUARTERS
              </h1>
              <p className=" text-secondary text-base font-semibold">
                Miami, Florida USA
              </p>
            </div>
          </Fade>
          <Fade bottom>
            <div className="mb-10">
              <h1 className="text-2xl font-extrabold italic pb-1 text-secondary">
                EMAIL ADDRESS
              </h1>
              <p className=" text-secondary text-base font-semibold">
                alistapesnft@gmail.com
              </p>
            </div>
          </Fade>
          {/* <Fade bottom>
            <div className="mb-10">
              <h1 className="text-2xl font-extrabold italic pb-1 text-secondary">
                PHONE NUMBER
              </h1>
              <p className=" text-secondary text-base font-semibold">
                954-830-5780
              </p>
            </div>
          </Fade> */}


          <Fade bottom>
            <div className="mb-10">
              <h1 className="text-2xl font-extrabold italic pb-1 text-secondary">
                Our Contract
              </h1>
              <a className=" text-secondary text-base font-semibold" href={`https://etherscan.io/address/${config.maleContract}#readContract`}>
              MALE:{config.maleContract}
              </a>
              <br/>

              <a className=" text-secondary text-base font-semibold" href={`https://etherscan.io/address/${config.femaleContract}#readContract`}>
              WIVE:{config.femaleContract}
              </a>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default Footer;
