import React from "react";
import pica from "../assets/images/IMG_2558.PNG";
import picb from "../assets/images/Untitled design (21).png";
import team1 from "../assets/images/11d82015da527691980163e607006a63.png";
import team2 from "../assets/images/653de415789a352663e8e044dbed3313.png";
import { FaDiscord } from "react-icons/fa";
import { Fade } from "react-reveal";
import YouTube from "react-youtube";

const opts = {
  // height: "120",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
  },
};

const HowToMint = () => {
  return (
    <div className="w-full overflow-hidden">
      <div className="max-w-6xl mx-auto px-4 py-20">
   



        <h1 className=" text-center text-primary font-extrabold italic text-4xl md:text-6xl uppercase mb-10">
             How to Mint?
              </h1>
        <div className="max-w-5xl mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mb-10">
            <div>
              <Fade bottom>
                <h1 className="text-white text-center text-3xl md:text-4xl font-bold mb-5 md:mb-10">
                  On Mobile
                </h1>
              </Fade>
              <Fade bottom>
                {/* <img
                  src={team1}
                  alt=""
                  className="w-full md:h-60 md:object-cover"
                /> */}

                <div className="w-full md:h-60 md:object-cover">
                  <YouTube
                    videoId="irC_lqXL6lE"
                    opts={opts}
                    className="w-full md:h-60 md:object-cover"
                  />
                </div>
              </Fade>
            </div>
            <div>
              <Fade bottom>
                <h1 className="text-white text-center text-3xl md:text-4xl font-bold mb-5 md:mb-10">
                  On Desktop
                </h1>
              </Fade>
              <Fade bottom>
              <div className="w-full md:h-60 md:object-cover">
                  <YouTube
                    videoId="y_1cbOcgafE"
                    opts={opts}
                    className="w-full md:h-60 md:object-cover"
                  />
                </div>


              </Fade>
            </div>

    
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToMint;
